<template>
  <b-card class="shop-delivery-settings">
    <div class="row">
      <div class="cols-12">
        <b-form-group>
          <b-form-input
            style="width: 145px;"
            v-model="searchInput"
            :placeholder="$t('Search')"
          />
        </b-form-group>
      </div>
    </div>
    <b-tabs
      pills
      vertical
      class="user-profile-info justify-content-center mb-5"
    >
      <b-tab v-if="canShowTab('paypal')" active>
        <template #title>
          Paypal
        </template>
        <div class="wrap-paypal-config">
          <div class="col-md-12 d-flex justify-content-center mb-2">
            <span class="d-inline"> <img
              :src="`${$apiUrl}/images/icons/paypal.svg`"
              class="mr-0 profile-img-height"
            ></span>
          </div>
          <div class="col-md-12">
            <p class="text-left font-15 font-weight-bold">
              {{ $t('Important') }}:
            </p>
            <ul>
              <li>
                Effective 1 April, 2021, PayPal will no longer offer domestic payment services in India. As a result of this, PayPal Payments Private Limited users cannot use Indian rupees (INR) to
                receive payments. This change, however, has no impact on PayPal's international services. Users in India can continue to send and receive international payments in US dollars (USD)
              </li>
              <li>You can read more about it from <a
                target="_BLANK"
                href="https://developer.paypal.com/api/nvp-soap/currency-codes/"
              >here</a></li>
            </ul>
          </div>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('App Id')">
                <b-form-input
                  v-model="configs.paypal_app_id"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('Client Id')">
                <b-form-input
                  v-model="configs.paypal_client_id"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('Client Secret')">
                <b-form-input
                  v-model="configs.paypal_client_secret"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="configs.paypal"
                name="paypal"
                switch
                inline
              >
                <span> {{ $t('Paypal') }}</span>
              </b-form-checkbox>
            </b-col>
            <div class="col-md-12 mt-3 text-center">
              <button
                class="btn btn-primary"
                @click="savePaypalDetails"
              >
                {{ $t('Save') }}
              </button>
            </div>
          </b-row>
        </div>
      </b-tab>
      <b-tab v-if="canShowTab('stripe')">
        <template #title>
          Stripe
        </template>
        <div class="wrap-stripe-config">
          <div class="col-md-12 d-flex justify-content-center mb-2">
            <span class="d-inline"> <img
              :src="`${$apiUrl}/images/icons/stripe.svg`"
              class="mr-0 profile-img-height"
            ></span>
          </div>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('Secret Key')">
                <b-form-input
                  v-model="configs.stripe_secret_key"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('Stripe Publishable Key')">
                <b-form-input
                  v-model="configs.stripe_publishable_key"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="configs.stripe"
                name="stripe"
                switch
                inline
              >
                <span> {{ $t('Stripe') }}</span>
              </b-form-checkbox>
            </b-col>
            <div class="col-md-12 mt-3 text-center">
              <button
                class="btn btn-primary"
                @click="saveStripeDetails"
              >
                {{ $t('Save') }}
              </button>
            </div>
          </b-row>
        </div>
      </b-tab>
      <b-tab v-if="canShowTab('phonepe')">
        <template #title>
          Phone Pe
        </template>
        <div class="wrap-stripe-config">
          <div class="col-md-12 d-flex justify-content-center mb-2">
            <span class="d-inline"> <img
              :src="`${$apiUrl}/images/icons/phonepe.svg`"
              class="mr-0 profile-img-height"
            ></span>
          </div>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('Merchant Id')">
                <b-form-input
                  v-model="configs.phonepe_mid"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('PhonePe Key One')">
                <b-form-input
                  v-model="configs.phonepe_key_one"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('PhonePe Key Two')">
                <b-form-input
                  v-model="configs.phonepe_key_two"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="configs.phonepe"
                name="phonepe"
                switch
                inline
              >
                <span> {{ $t('PhonePe') }}</span>
              </b-form-checkbox>
            </b-col>
            <div class="col-md-12 mt-3 text-center">
              <button
                class="btn btn-primary"
                @click="saveStripeDetails"
              >
                {{ $t('Save') }}
              </button>
            </div>
          </b-row>
        </div>
      </b-tab>
      <b-tab v-if="canShowTab('razorpay')">
        <template #title>
          Razor Pay
        </template>
        <div class="wrap-stripe-config">
          <div class="col-md-12 d-flex justify-content-center mb-2">
            <span class="d-inline"> <img
              :src="`${$apiUrl}/images/icons/razorpay.svg`"
              class="mr-0 profile-img-height"
            ></span>
          </div>
          <div class="col-md-12">
            <p class="text-left">
              {{ $t('Integration Guide') }}:
            </p>
            <ul>
              <li>
                {{ $t('Login on Razorpay') }} - <a href="https://razorpay.com/ ">https://razorpay.com/ </a>{{ $t('If you are a new user, consider registering by clicking') }} -
                <a href="https://dashboard.razorpay.com/signup">https://dashboard.razorpay.com/signup</a>
              </li>
              <li>{{ $t('Complete registration (If new user)') }}</li>
              <li>{{ $t('Click Live mode on top') }}</li>
              <li>{{ $t('API_KEY_SETTINGS') }}</li>
              <li>{{ $t('Generate the keys (Here you will find both the required keys)') }}</li>
            </ul>
          </div>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('Secret Key')">
                <b-form-input
                  v-model="configs.razor_secret"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('Razor Pay Key')">
                <b-form-input
                  v-model="configs.razor_key"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="configs.razor"
                name="razor"
                switch
                inline
              >
                <span> {{ $t('Razor Pay') }}</span>
              </b-form-checkbox>
            </b-col>
            <div class="col-md-12 mt-3 text-center">
              <button
                class="btn btn-primary"
                @click="saveStripeDetails"
              >
                {{ $t('Save') }}
              </button>
            </div>
          </b-row>
        </div>
      </b-tab>
      <b-tab v-if="canShowTab('flutterwave')">
        <template #title>
          Flutterwave 
        </template>
        <div class="wrap-stripe-config">
          <div class="col-md-12 d-flex justify-content-center mb-2">
            <span class="d-inline"> <img
              :src="require('@/assets/images/app-icons/sub-menu/Logo_FullColor.png')"
              class="mr-0 profile-img-height"
            ></span>
          </div>
          <div class="col-md-12">
            <p class="text-left">
              {{ $t('Integration Guide') }}:
            </p>
            <ul>
              <li>
                {{ $t('Login on Flutterwave') }} - <a href="https://app.flutterwave.com/ ">https://app.flutterwave.com/ </a>{{ $t('If you are a new user, consider registering by clicking') }} -
                <a href="https://app.flutterwave.com/register">https://app.flutterwave.com/register</a>
              </li>
              <li>{{ $t('Complete registration (If new user)') }}</li>
              <li>{{ $t('Go to Settings (Present in menu)') }}
                > API keys  <a href="https://app.flutterwave.com/dashboard/settings/apis/live/ ">https://app.flutterwave.com/dashboard/settings/apis/live/ </a>
              </li>
              <li>{{ $t('Update to live mode') }}</li>
              <li>{{ $t('Generate the keys (Here you will find all the required keys)') }}</li>
            </ul>
          </div>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('Public Key')">
                <b-form-input
                  v-model="metaFlutterWave.publicKey"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('Secret Key')">
                <b-form-input
                  v-model="metaFlutterWave.secretKey"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('Secret Hash')">
                <b-form-input
                  v-model="metaFlutterWave.secretHash"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('Base URL')">
                <b-form-input
                  v-model="metaFlutterWave.baseUrl"
                  type="text"
                />
              </b-form-group>
            </b-col> -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="configs.flutterwave"
                name="razor"
                switch
                inline
              >
                <span> {{ $t('Flutterwave') }}</span>
              </b-form-checkbox>
            </b-col>
            <div class="col-md-12 mt-3 text-center">
              <button
                class="btn btn-primary"
                @click="saveStripeDetails"
              >
                {{ $t('Save') }}
              </button>
            </div>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useProviderUi } from './useProvider'
import { isEmpty } from '@/utilities'

export default {
  data() {
    return {
      configs: {},
      searchInput: '',
      metaFlutterWave: {},
      meta: {},
    }
  },
  mounted() {
    this.paymentConfigs()
  },
  methods: {
    canShowTab(tabName) {
      if (!this.searchInput) return true
      return tabName.toLowerCase().includes(this.searchInput.toLowerCase())
    },
    saveStripeDetails() {
      const formData = new FormData()
      Object.keys(this.configs).forEach(item => {
        // eslint-disable-next-line eqeqeq
        if (this.configs[item] == undefined) {
          this.configs[item] = ''
        }
      })
      formData.append('_method', 'PATCH')
      formData.append('paypal_app_id', this.configs.paypal_app_id)
      formData.append('paypal_client_id', this.configs.paypal_client_id)
      formData.append('paypal_client_secret', this.configs.paypal_client_secret)
      formData.append('paypal', this.configs.paypal ? 1 : 0)

      formData.append('stripe_publishable_key', this.configs.stripe_publishable_key)
      formData.append('stripe_secret_key', this.configs.stripe_secret_key)
      formData.append('stripe', this.configs.stripe ? 1 : 0)

      formData.append('phonepe_mid', this.configs.phonepe_mid)
      formData.append('phonepe_key_one', this.configs.phonepe_key_one)
      formData.append('phonepe_key_two', this.configs.phonepe_key_two)
      formData.append('phonepe', this.configs.phonepe ? 1 : 0)

      formData.append('razor_key', this.configs.razor_key)
      formData.append('razor_secret', this.configs.razor_secret)
      formData.append('razor', this.configs.razor ? 1 : 0)

      formData.append('flutterwave', this.configs.flutterwave ? 1 : 0)
      if(!isEmpty(this.metaFlutterWave)){
        this.meta.flutterwave = this.metaFlutterWave
      }
      if(!isEmpty(this.meta)){
        formData.append('meta', JSON.stringify(this.meta))
      }

      const { updatePaymentConfig } = useProviderUi()

      showLoader()
      updatePaymentConfig(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    savePaypalDetails() {
      const { updatePaymentConfig } = useProviderUi()
      const formData = new FormData()
      Object.keys(this.configs).forEach(item => {
        // eslint-disable-next-line eqeqeq
        if (this.configs[item] == undefined) {
          this.configs[item] = ''
        }
      })
      formData.append('_method', 'PATCH')
      formData.append('paypal_app_id', this.configs.paypal_app_id)
      formData.append('paypal_client_id', this.configs.paypal_client_id)
      formData.append('paypal_client_secret', this.configs.paypal_client_secret)
      formData.append('paypal', this.configs.paypal ? 1 : 0)
      showLoader()
      updatePaymentConfig(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    paymentConfigs() {
      showLoader()
      const { paymentConfig } = useProviderUi()

      paymentConfig()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.configs = data.responseData
            this.metaFlutterWave = {
              publicKey: null,
              secretKey: null,
              secretHash: null,
              baseUrl: null,
            }
            let {meta} = this.configs
            if(!isEmpty(meta)){
              meta = JSON.parse(meta)
              this.meta = meta
              const {flutterwave} = meta
              if(!isEmpty(flutterwave)){
                this.metaFlutterWave = flutterwave
              }
            }
            this.configs.paypal = this.configs.paypal === 1
            this.configs.stripe = this.configs.stripe === 1
            this.configs.phonepe = this.configs.phonepe === 1
            this.configs.razor = this.configs.razor === 1
            this.configs.flutterwave = this.configs.flutterwave === 1
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style>
.provider-payment-config .user-profile-info ul.nav.nav-pills {
  justify-content: center;
}

.profile-img-height {
  height: 40px;
}

.provider-payment-config .nav-pills .nav-link {
  padding: 10px;
}

[dir] .provider-payment-config .nav-pills .nav-link.active,
[dir] .provider-payment-config .nav-pills .show > .nav-link {
  background-color: #c6c3e1;
}
</style>

<style scoped>
.button-type-style {
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-weight: 300;
  cursor: pointer;
}

.button-type-style.active {
  background: #7367f0 !important;
}

.button-type-style.active label {
  color: #fff;
}
</style>

<style lang="scss">
.shop-delivery-settings .user-profile-info ul.nav.nav-pills {
  justify-content: center;
}

.shop-delivery-settings ul .nav-item a {
  justify-content: flex-start;

  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
}

.shop-delivery-settings {
  .user-profile-info {
    .col-auto {
      border-right: 3px solid #d8d6de;
    }
  }

  @media only screen and (max-width: 767px) {
    .user-profile-info {
      display: block;
      .col-auto {
        border-right: 0;
      }
    }
  }
}
</style>